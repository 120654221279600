import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { CircularProgress, Button } from '@mui/material';
import { PlusOneOutlined } from '@mui/icons-material';
import data, { urlapi } from '../../lib/backend/data';
const bucketname = data.bucketUrl;

const CargaArchivo = (props) => {
    const {
        default_image,
        component_upload,
        stopImageOnButton
    } = props;
    const stopImageOnButton_ = typeof stopImageOnButton === 'undefined' ? false : stopImageOnButton;
    const image = default_image ? default_image : '';
    const [loadingMedios, setLoadingMedios] = useState(true);
    const [imageUrl, setImageUrl] = useState(image);
    const [uploading, setUploading] = useState(false);
    const sesion = useSelector(state => state.miusuario)
    const [uploadPorcentaje, setUploadPorcentaje] = useState(0);
    const token = sesion?.tokenSession

    const handleSubmitV2 = async (file) => {
        const name = `${Date.now()}-${file.name}`;
        const original_name = file.name;
        const type = file.type;
        const size = file.size;
        const medio = {
            nombre: original_name,
            type,
            size,
            nombre_server: name,
        };
        const url_firmada = await obtenerFirmaUrl(name, type);
        if (!url_firmada) return false;
        setUploading(true);
        try {
            await axios.put(url_firmada, file, {
                headers: {
                    'Content-Type': file.type
                },
                onUploadProgress: (e) => {
                    const { loaded, total } = e;
                    let porcentaje = Math.floor((loaded * 100) / total);
                    setUploadPorcentaje(porcentaje);
                }
            });
            const url = `${bucketname}/${name}`;
            if (props.onChange) props.onChange(url);
            if (!stopImageOnButton_) setImageUrl(url);
            setUploading(false);
        } catch (error) {
            setUploading(false);
            console.error(error.message);
            alert("No se pudo cargar este archivo");
        }
    };

    const obtenerFirmaUrl = async (name, type) => {
        try {
            const res = await fetch(`${urlapi}/medios/presigne-url-public`, {
                method: 'POST',
                body: JSON.stringify({
                    name,
                    type
                }),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer: ${token}`
                }
            });

            if (res.status === 401) {
                window.location = '/login';
                return false;
            }

            const result = await res.json();
            if (!result) {
                alert('Sin datos');
                return false;
            } else if (result.errorMessage) {
                alert(result.errorMessage);
                return false;
            } else if (result.url) {
                return result.url;
            }

            return false;
        } catch (error) {
            alert("Error al consultar la información, intente nuevamente");
            return false;
        }
    };

    const uploadButton = component_upload ? (
        <div>
            {uploading ? <CircularProgress /> : component_upload}
        </div>
    ) : (
        <Button
            variant="outlined"
            startIcon={uploading ? <CircularProgress size={20} /> : false }
            disabled={uploading}
            component="span"
        >
            {uploading ? "SUBIENDO..." : "SUBIR"}
        </Button>
    );

    const beforeUpload = (file) => {
        return true; // Aquí puedes agregar tus validaciones de archivos si es necesario
    };

    const customRequest = async ({ file }) => {
        try {
            await handleSubmitV2(file);
        } catch (error) {
            alert('Error al subir el archivo: ' + error.message);
        }
    };

    const component_subida = (
        <div className="">
            <input
                accept="image/*"
                style={{ display: 'none' }}
                id="raised-button-file"
                type="file"
                onChange={(e) => customRequest({ file: e.target.files[0] })}
            />
            <label htmlFor="raised-button-file">
                {uploadButton}
                {/* {imageUrl ? (
                    <img
                        src={imageUrl}
                        alt="avatar"
                        style={{
                            width: '100%',
                        }}
                    />
                ) : (
                    uploadButton
                )} */}
            </label>
        </div>
    );

    return (
        <div>
            {component_subida}
        </div>
    );
};

export default CargaArchivo;
