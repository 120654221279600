import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import ShareLocationIcon from '@mui/icons-material/ShareLocation';
import SettingsIcon from '@mui/icons-material/Settings';
import StarIcon from '@mui/icons-material/Star';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import socket from '../../lib/websockets';
import toast from 'react-hot-toast';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Badge from '@mui/material/Badge';
import { Button, Card, Grid } from '@mui/material';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { fechaATexto } from '../../lib/helpers/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { urlapi } from '../../lib/backend/data';
import { cerrarSesion } from '../../redux/actions/session';
import SelectorPais from './selector_pais';
import SelectorIdioma from './selector_idioma';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ModeOfTravelIcon from '@mui/icons-material/ModeOfTravel';
import { BiSolidUserAccount } from 'react-icons/bi'
import { rutas } from '../../lib/data/rutas';

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

export default function Structure(props) {
  const theme = useTheme();
  const [open, setOpen] = useState(true)
  const [ notificaciones, setNotificaciones ] = useState([])
  const [ notificacionesNuevas, setNotificacionesNuevas ] = useState([])
  const sesion = useSelector(state => state.miusuario)
  const dispatch = useDispatch()
  const notis = useRef([])
  const notisNuevas = useRef([])
  notis.current = notificaciones
  notisNuevas.current = notificacionesNuevas
  const sizeIcon = 20
  
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const procesarMensajes = async (datos) => {

    const nuevas_notificaciones_nuevas = notisNuevas.current
    nuevas_notificaciones_nuevas.push(datos)
    setNotificacionesNuevas(prev => [...[], ...nuevas_notificaciones_nuevas])

    const nuevas_notificaciones = notis.current
    nuevas_notificaciones.push(datos)
    setNotificaciones(prev => [...[], ...nuevas_notificaciones])
  }

  const testWebsocket = datos => {
    console.log({ socket: datos })
    toast.success("Socket recibiendo correctamente")
  }

  const descartarNotBD = async (id) => {
    return fetch(`${urlapi}/notificaciones/discard?id=${id}`,{
      method:'GET',
      headers: {
          'Content-Type':'application/json',
          'Authorization': `Bearer: ${sesion.tokenSession}`
      }
    })
    .then(res => res.json())
    .then(res => res)
    .catch(error => error)
  }

  const descartarNotificacion = (i) => {
    if(notificacionesNuevas[i]) descartarNotBD(notificacionesNuevas[i]._id)
    notificacionesNuevas.splice(i,1)
    return setNotificacionesNuevas(prev => [...[], ...notificacionesNuevas])
  }

  const mostrarNotificacionesNuevas = () => {
    if(notificacionesNuevas.length < 1) return false
    return <div>
      {
        notificacionesNuevas.map((not,inot) => {
          return <Card sx={{ p:2, mb:1}}>
            <Grid container>
              <Grid item xs={9}>
                <Typography variant="p" sx={{ display: "block", mb:0 }}><NotificationsNoneIcon/> {not.descripcion}</Typography>
                <Typography variant="p" sx={{ fontSize: 12, m:0 }}>{fechaATexto(not.fecha_zona_horaria_local)}</Typography>
              </Grid>
              <Grid item xs={3} sx={{ textAlign: "right" }} >{ not.url_slug ? <Link target="_blank" to={not.url_slug}><Button>VER DETALLES</Button></Link> : false }<Button onClick={() => descartarNotificacion(inot)}>DESCARTAR</Button> </Grid>
            </Grid>
          </Card>
        })
      }
    </div>
  }
  const obtenerNotificaciones = async () => {
    return fetch(`${urlapi}/notificaciones/conteo`,{
      method:'GET',
      headers: {
          'Content-Type':'application/json',
          'Authorization': `Bearer: ${sesion.tokenSession}`
      }
  })
  .then(res => {
      if(res.status === 401) return dispatch(cerrarSesion())
      return res.json()
  })
  .then(res => {
      if(!res){
          toast.error('Sin datos')
          return false
      } else if(res.errorMessage){
          return false
      } else if(res.conteo){
          setNotificaciones(res.conteo)
          notis.current = res.conteo
      }
      return false
  })
  .catch(error => {
      return false
  })
  }
  
  useEffect(() => {
    obtenerNotificaciones()
    const identificador = 'global_notification'
    const identificador_2 = 'testing_websocket'
    socket.on(identificador, procesarMensajes)
    socket.on(identificador_2, testWebsocket)
    return () => {
      socket.off(identificador, procesarMensajes)
      socket.off(identificador_2, testWebsocket)
    }
  }, [])

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position='fixed' open={open}>
        <Toolbar>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            onClick={handleDrawerOpen}
            edge='start'
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant='h6' noWrap component='div'>
            MOVING TRUCK 76.2
          </Typography>
          <Link to="/notifications">
          <IconButton
              size="large"
              aria-label="notifications"
              color="inherit"
            >
              {notificaciones.length > 0 ? <Badge badgeContent={notificaciones.length} color="error"><NotificationsIcon sx={{ color: "white" }} /></Badge> : <NotificationsIcon sx={{ color: "white" }} /> }
            </IconButton>
          </Link>
          <Box sx={{ mr:2, display: { xs: 'flex'} }}><SelectorPais /></Box>
          <Box sx={{ mr:2, display: { xs: 'flex'} }}><SelectorIdioma /></Box>
          <Button sx={{ color: "white" }} onClick={() => dispatch(cerrarSesion(false) )}>CERRAR SESIÓN</Button>
        </Toolbar>
      </AppBar>
      <Drawer variant='permanent' open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {[
            /*{ name: 'Usuarios', link: '/users', icon: <PeopleAltIcon /> },*/
            {
              name: 'Operación',
              link: '/operation',
              icon: <ShareLocationIcon />,
            },
            { 
              name: 'Conductores', 
              link: '/drivers', 
              icon: <PersonPinIcon /> 
            },
            {
              name: 'Vehículos',
              link: '/vehicles',
              icon: <DirectionsCarIcon />,
            },
            {
              name: rutas.ordenes.title,
              link: rutas.ordenes.slug,
              icon: <PersonSearchIcon />,
            },
            {
              name: rutas.reportes_viajes.title,
              link: rutas.reportes_viajes.slug,
              icon: <DirectionsCarIcon />,
            },
            {
              name: 'Equipos',
              link: rutas.equipments.slug,
              icon: <DirectionsCarIcon />,
            },
            {
              name: 'Contactos',
              link: rutas.contacts.slug,
              icon: <BiSolidUserAccount size={sizeIcon} />,
            },
            {
              name: 'Destinatarios',
              link: rutas.destinatarios.slug,
              icon: <BiSolidUserAccount size={sizeIcon} />,
            },
            {
              name: rutas.estados_carga.title,
              link: rutas.estados_carga.slug,
              icon: <BiSolidUserAccount size={sizeIcon} />,
            },
            {
              name: rutas.productos.title,
              link: rutas.productos.slug,
              icon: <BiSolidUserAccount size={sizeIcon} />,
            },
            {
              name: rutas.costos.title,
              link: rutas.costos.slug,
              icon: <BiSolidUserAccount size={sizeIcon} />,
            },
            {
              name: 'Empresas',
              link: '/empresas',
              icon: <BusinessCenterIcon />,
            },
            {
              name: 'Empresas - Clientes',
              link: '/empresas/clientes',
              icon: <BusinessCenterIcon />,
            },
            {
              name: 'Clientes',
              link: '/clients',
              icon: <PersonSearchIcon />,
            },
            {
              name: rutas.bodegas.title,
              link: rutas.bodegas.slug,
              icon: <PersonSearchIcon />,
            },
            {
              name: 'Calificaciones',
              link: '/ratings',
              icon: <StarIcon />,
            },
            {
              name: 'Atención al cliente',
              link: '/customer-service',
              icon: <SupportAgentIcon />,
            },
            {
              name: 'Ganancias',
              link: '/earnings',
              icon: <AttachMoneyIcon />,
            },
            {
              name: rutas.custom_fields.title,
              link: rutas.custom_fields.slug,
              icon: <AttachMoneyIcon />,
            },
            {
              name: rutas.categories.title,
              link: rutas.categories.slug,
              icon: <AttachMoneyIcon />,
            },
            {
              name: 'Configuración',
              link: '/configuration',
              icon: <SettingsIcon />,
            }
          ].map((menu, index) => (
            <ListItem key={menu.name} disablePadding sx={{ display: 'block' }}>
              <Link to={menu.link} style={{ color: 'black', textDecoration: "none" }}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                    }}
                  >
                    {menu.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={menu.name}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </Link>
            </ListItem>
          ))}
        </List>
      </Drawer>
      <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        {mostrarNotificacionesNuevas()}
        {props.component}
      </Box>
    </Box>
  );
}
