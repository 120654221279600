import AlgoritmoBusqueda from "./Configuracion/algoritmoBusqueda";
import ContentConfiguracion from "./Configuracion/content";
import Impuestos from "./Configuracion/impuesto";
import ConfigProfit from "./Configuracion/profit";
import CotizacionSugerencias from "./Configuracion/sugerencias_cotizaciones";

export const ConfiguracionProfit = (props) => (
    <ContentConfiguracion component={<ConfigProfit />} {...props} />
);
export const ConfiguracionImpuesto = (props) => (
    <ContentConfiguracion component={<Impuestos />} {...props} />
);

export const ConfiguracionSugerencias = (props) => (
    <ContentConfiguracion component={<CotizacionSugerencias />} {...props} />
);

export const ConfiguracionAlgoritmoBusqueda = (props) => (
    <ContentConfiguracion component={<AlgoritmoBusqueda />} {...props} />
);